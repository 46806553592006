import React from "react";
import {
  mapping,
  ButtonSolid,
  ConfirmationPopup,
  userProfileStyle,
} from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

// this part i think can use AutoComplete from styleguide

class ChangePasswordPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "",
      new1: "",
      new2: "",
    };
  }

  render() {
    //console.log(this.props.passwordChanged)
    return (
      <div>
        <div style={userProfileStyle.RiskCreationFormWrapStyle}>
          <div style={userProfileStyle.RiskCreationFormStyle}>
            <div style={userProfileStyle.formBgStyle}>
              <div
                style={{
                  ...mapping["heading/h5/lightleft"],
                  marginBottom: "2rem",
                }}
              >
                Change your password
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <PasswordInputField
                  title={"Current password: "}
                  value={this.state.current}
                  onChange={(event) => {
                    this.setState({ current: event.target.value });
                  }}
                />
                <div style={{ height: "1rem" }}>
                  <WarningMsg
                    value={this.WarningMsgCurrent(this.props.passwordError)}
                  />
                </div>
                <PasswordInputField
                  title={"New password: "}
                  value={this.state.new1}
                  onChange={(event) => {
                    this.setState({ new1: event.target.value });
                  }}
                />
                <div style={{ height: "1rem" }}>
                  <WarningMsg
                    value={this.WarningMsgNew1(this.props.passwordError)}
                  />
                </div>
                <PasswordInputField
                  title={"Confirm new password: "}
                  value={this.state.new2}
                  onChange={(event) => {
                    this.setState({ new2: event.target.value });
                  }}
                />
                <div style={{ height: "1rem" }}>
                  <WarningMsg
                    value={this.WarningMsgNew(this.props.passwordError)}
                  />
                </div>
              </div>
              <div style={userProfileStyle.buttonWrapper}>
                <ButtonSolid
                  name={"Cancel"}
                  color={"Secondary"}
                  width={userProfileStyle.buttonWidth}
                  height={userProfileStyle.buttonHeight}
                  clickEvent={this.props.cancelFunction}
                />
                <ButtonSolid
                  name={"Confirm"}
                  color={"Primary"}
                  width={userProfileStyle.buttonWidth}
                  height={userProfileStyle.buttonHeight}
                  clickEvent={() => {
                    this.props.submitFunction(this.state);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.props.passwordChanged && (
          <ConfirmationPopup
            title={"Password Change Successfully"}
            content={
              "your password has been changed, please login with the new password again"
            }
            cancelFunction={() => {
              navigateToUrl("/login");
            }}
            confirmFunction={() => {
              navigateToUrl("/login");
            }}
          />
        )}
      </div>
    );
  }
  WarningMsgCurrent = (passwordError) => {
    const passwordRegex = new RegExp(
      "^(^(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$)?(^(?=.*d)(?=.*[a-z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*d)(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?$"
    );
    const passwordValidator = (value) =>
      !value
        ? "Field can not be empty"
        : passwordRegex.test(value)
        ? "valid"
        : "Please enter a valid password.";

    if (passwordError.currentEmpty) {
      return "Please enter your current password.";
    } else if (passwordError.currentWrong) {
      return "Incorrect password, please re-enter.";
    } else if (
      passwordValidator(this.state.current) !== "valid" &&
      this.state.current !== ""
    ) {
      return "Please enter a valid current password.";
    }
  };
  WarningMsgNew = (passwordError) => {
    if (passwordError.newEmpty) {
      return "Please enter your new password.";
    } else if (
      this.state.new1 !== "" &&
      this.state.new2 !== "" &&
      this.state.new1 !== this.state.new2
    ) {
      return "Passwords do not match, please check again.";
    }
  };
  WarningMsgNew1 = (passwordError) => {
    const passwordRegex = new RegExp(
      "^(^(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$)?(^(?=.*d)(?=.*[a-z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*d)(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?$"
    );
    const passwordValidator = (value) =>
      !value
        ? "Field can not be empty"
        : passwordRegex.test(value)
        ? "valid"
        : "Please enter a valid password.";

    if (
      passwordError.newInvalid ||
      (passwordValidator(this.state.new1) !== "valid" && this.state.new1 !== "")
    ) {
      return "Please enter a valid new password.";
    }
  };
}

const FieldTitle = (props) => {
  return (
    <div style={userProfileStyle.fieldTitleWrapper}>
      <div style={mapping["paragraph/default/lightleft"]}>{props.value} </div>
    </div>
  );
};
const PasswordInputField = (props) => {
  return (
    <div style={{ marginTop: "2.5rem", marginBottom: "0.5rem" }}>
      <FieldTitle value={props.title} />
      <input
        type={"password"}
        value={props.value}
        style={userProfileStyle.popupInputFieldStyle}
        onChange={props.onChange}
      />
    </div>
  );
};
const WarningMsg = (props) => {
  return <div style={userProfileStyle.warningMSGStyle}>{props.value}</div>;
};

export { ChangePasswordPopup };
